import IEvent from "../../models/eventItem";
import IKalenderItem from "../../models/kalenderItem";
import IFotoVDMaand from "../../models/fotovdmaand";

export function stringToNumber(str: string): number {
    return parseInt(str.split("-").reverse().join(""));
}

export function sortAgendaItems(items: IKalenderItem[]): IKalenderItem[] {
    return items.sort((a, b) => {
            let dateA = new Date(a.datum + "T" + a.van);
            let dateB = new Date(b.datum + "T" + b.van);
            return dateA.getTime() - dateB.getTime();
        }
    )
}

export function sortFotoVDMaand(items: IFotoVDMaand[]): IFotoVDMaand[] {
    return items.sort((a, b) => a.maand - b.maand)
}

export const geweest = (datum: string): boolean => {
    let today = new Date();
    let date = new Date(datum);
    if (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDay() === today.getDay()) return false;
    return date < today
}

export function SortAndFilterAgendaItems(items: IKalenderItem[]): IKalenderItem[] {
    return sortAgendaItems(items).filter((item) => {
        return !geweest(item.datum)
    })
}

export function sortEventsAsc(items: IEvent[]): IEvent[] {
    return items.sort((a, b) => {
        return stringToNumber(a.datum) - stringToNumber(b.datum)
    })
}

export function sortEventsDesc(items: IEvent[]): IEvent[] {
    return items.sort((b, a) => {
        return stringToNumber(a.datum) - stringToNumber(b.datum)
    })
}

export function sortEventsDescWithLimit(items: IEvent[]): IEvent[] {
    return items.sort((b, a) => {
        return stringToNumber(a.datum) - stringToNumber(b.datum);
    });
}
