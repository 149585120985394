import IEvent from "../../models/eventItem";
import React from "react";
import {Alerts} from "../CONSTANTS";
import {geweest} from "./sorters";
import IKalenderItem from "../../models/kalenderItem";

export function getUniqueSoortElements(events: IEvent[]): string[] {
    const uniqueSoortElements: Set<string> = new Set();
    events.forEach((event) => {
        if (event.soort) {
            uniqueSoortElements.add(event.soort);
        }
    });
    return Array.from(uniqueSoortElements);
}

export function monthNumberToString(month: any): string {
    let input, returnValue;
    if(typeof month === "string") input = parseInt(month);
    else input = month;
    switch (input) {
        case 1:
            returnValue = "januari"
            break;
        case 2:
            returnValue =  "februari";
            break;
        case 3:
            returnValue =  "maart";
            break;
        case 4:
            returnValue =  "april";
            break;
        case 5:
            returnValue =  "mei";
            break;
        case 6:
            returnValue =  "juni";
            break;
        case 7:
            returnValue =  "juli";
            break;
        case 8:
            returnValue =  "augustus";
            break;
        case 9:
            returnValue =  "september";
            break;
        case 10:
            returnValue =  "oktober";
            break;
        case 11:
            returnValue =  "november";
            break;
        case 12:
            returnValue =  "december";
            break;
        default:
            returnValue =  month;
            break;
    }
    return returnValue;
}

export function nextMeeting(sortedAgendaItems: IKalenderItem[]): string {
    const upcomingMeeting = sortedAgendaItems.find((item) => !geweest(item.datum));
    if (!upcomingMeeting) return "Info volgt z.s.m.";
    return upcomingMeeting.datum;
}

export function datumToMonthNumber(datum: string): number {
    return parseInt(datum.split("-")[1]);
}

export function datumDigitsToString(datum: string): string {
    let inputDate = new Date(datum)
    let today = new Date();
    const day = inputDate.getDate()
    const month = inputDate.getMonth() + 1;
    const year = inputDate.getFullYear();
    if(day === today.getDate() && month === today.getMonth() + 1 && year === today.getFullYear()) return "Vandaag";
    return `${day} ${monthNumberToString(month)} ${year}`;
}

export function rightMouseBlocker(event: React.MouseEvent): void {
    if (event.button === 2) {
        event.preventDefault();
        alert(Alerts.Copyright)
    }
}

export function generatePrompt(key: string) {
    switch (key) {
        case "fotograaf":
            return `Wat is de nieuwe ${key}?`;
        case "alt":
            return `Wat is de nieuwe ${key}?`;
        case "maand":
            return `Wat is de nieuwe ${key}?`;
        case "jaar":
            return `Wat is het nieuwe ${key}?`;
        default:
            return `Wat is de nieuwe waarde?`;
    }
}
