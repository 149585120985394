import React, {useEffect} from 'react';
import "./FotoVdMaand.css";
import IFotoVDMaand from "../../../models/fotovdmaand";
import MaandFotoService from "../../../services/MaandFotoService";
import {generatePrompt, monthNumberToString} from "../../../utils/helpers/utils";
import {handleChangeOfState} from "../../../utils/helpers/test";
import {Alerts, Confirms} from "../../../utils/CONSTANTS";
import {sortFotoVDMaand} from "../../../utils/helpers/sorters";

function FotoVdMaand() {
    const [photos, setPhotos] = React.useState<IFotoVDMaand[]>([]);
    const [photo, setPhoto] = React.useState<IFotoVDMaand>({
        alt: "",
        createdAt: "",
        createdBy: "",
        fotograaf: "",
        id: "",
        link: "",
        maand: 0,
        jaar: 0
    })

    function isPhotoUnique(newPhoto: IFotoVDMaand, existingPhotos: IFotoVDMaand[]): boolean {
        return !existingPhotos.some(photo => (
            parseInt(String(photo.maand)) === parseInt(String(newPhoto.maand)) &&
            parseInt(String(photo.jaar)) === parseInt(String(newPhoto.jaar))
        ));
    }

    function createPhoto(photo: IFotoVDMaand) {
        if (photos.filter((item) => photo.link === item.link).length > 0) {
            alert(Alerts.FVDMAlreadyPickedPhoto)
            return
        }
        if (photo.maand < 1 || photo.maand > 12) {
            alert(Alerts.InvalidMonth)
            return
        }
        if (photo.jaar < new Date().getFullYear()) {
            alert(Alerts.PresentOrFuture)
            return
        }
        if (!isPhotoUnique(photo, photos)) {
            alert(Alerts.FVDMAlreadyPickedMonthEr)
            return
        }
        MaandFotoService
            .create(photo)
            .then((res) => setPhotos([...photos, {
                ...photo,
                id: res.id,
                createdAt: res.createdAt,
                createdBy: res.createdBy
            }]))
            .catch(e => console.error(e))
            .finally(() => resetForm());
    }

    function handleDelete(photo: IFotoVDMaand): void {
        if (window.confirm(Confirms.DeletePicture)) {
            let today = new Date()
            if (today.getMonth() + 1 === photo.maand && today.getFullYear() === photo.jaar) {
                alert(Alerts.FVDMCurrentMonth)
                return;
            }
            MaandFotoService
                .deleteFotoVDMaand(photo.id)
                .then(() => setPhotos(photos.filter((item) => item.id !== photo.id)))
                .catch(e => console.error(e))
        }
    }

    function resetForm(): void {
        setPhoto({alt: "", createdAt: "", createdBy: "", fotograaf: "", id: "", jaar: 0, link: "", maand: 0})
    }

    function getAll(): void {
        MaandFotoService
            .getAll()
            .then(res => setPhotos(res))
            .catch(e => console.error(e))
    }

    useEffect(() => {
        getAll()
    }, []);


    function updateField(id: string, key: string, currentValue: string): void {
        let newInput = prompt(generatePrompt(key), currentValue)
        if (newInput === null) return
        MaandFotoService
            .updateField(id, key, newInput)
            .then(() => getAll())
            .catch(e => console.error(e))
    }


    return (
        <div className={"fotovdmaand-container"}>
            <div className={"fotovdmaand-lijst"}>
                {sortFotoVDMaand(photos).map((photo, index) => (
                    <div className={"fotovdmaand-wrapper"} key={index}>
                        <img style={{cursor: "pointer"}} src={photo.link} alt={photo.alt}
                             onClick={() => updateField(photo.id, "link", photo.link)}/>
                        <div className={"fotovdmaand-info"}>
                            <p onClick={() => updateField(photo.id, "fotograaf", photo.fotograaf)}
                               className={"fotovdmaand-fotograaf"}>{photo.fotograaf}</p>
                            <p onClick={() => updateField(photo.id, "alt", photo.alt)}
                               className={"fotovdmaand-info-specs"}>{photo.alt}</p>
                            <p onClick={() => updateField(photo.id, "maand", photo.maand.toString())}
                               className={"fotovdmaand-maand"}>{monthNumberToString(photo.maand)}</p>
                            <p onClick={() => updateField(photo.id, "jaar", photo.jaar.toString())}
                               className={"fotovdmaand-maand"}>{photo.jaar} </p>
                            <button type={"button"} onClick={() => handleDelete(photo)}>Verwijder</button>

                        </div>
                    </div>
                ))}
            </div>
            <div className={"fotovdmaand-form"}>
                <h2>Voeg een foto van de maand toe</h2>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    createPhoto(photo)
                }}>
                    <input type="text"
                           value={photo.link}
                           name="link"
                           id="link"
                           onChange={(e) => handleChangeOfState(e, setPhoto)}
                           placeholder={"Vul hier url van de foto van de maand in"}
                    />
                    <input type="text"
                           value={photo.fotograaf}
                           name="fotograaf"
                           id="fotograaf"
                           onChange={(e) => handleChangeOfState(e, setPhoto)}
                           placeholder={"Vul hier de naam van de fotograaf in"}
                    />
                    <input type="text"
                           value={photo.alt}
                           name="alt"
                           id="alt"
                           onChange={(e) => handleChangeOfState(e, setPhoto)}
                           placeholder={"Vul hier het bijschrift van de foto in"}
                    />
                    <br/>
                    <label htmlFor="maand">Maandnummer</label>
                    <br/>
                    <input type="number"
                           value={photo.maand}
                           name="maand"
                           id="maand"
                           onChange={(e) => handleChangeOfState(e, setPhoto)}
                    />
                    <br/>
                    <label htmlFor="jaar">Jaar</label>
                    <br/>
                    <input type="number"
                           value={photo.jaar}
                           name="jaar"
                           id="jaar"
                           onChange={(e) => handleChangeOfState(e, setPhoto)}/>
                    <br/>
                    <button type="submit">Voeg toe</button>
                    <button type="button" onClick={() => resetForm()}>Reset</button>
                </form>
            </div>
        </div>
    );
}

export default FotoVdMaand;
