import {auth, db} from "../config/firebase";
import {addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where} from 'firebase/firestore';
import IFotoVDMaand from "../models/fotovdmaand";
import {Collections} from "../utils/CONSTANTS";

const ref = collection(db, Collections.fotovdmaand)

class MaandFotoService {

    async getAll(): Promise<Array<IFotoVDMaand>> {
        const snapshot = await getDocs(ref)
        const data: Array<any> = [];
        snapshot.docs.forEach((item) => {
            return data.push({
                id: item.id,
                ...item.data(),
            });
        });
        return data as Array<IFotoVDMaand>;
    };

    async create({alt, fotograaf, jaar, link, maand}: IFotoVDMaand): Promise<any> {
        return await addDoc(collection(db, Collections.fotovdmaand), {
            link: link,
            maand: parseInt(String(maand)),
            fotograaf: fotograaf,
            jaar: parseInt(String(jaar)),
            alt: alt,
            createdBy: auth?.currentUser?.uid,
            createdAt: Date.now(),
        });
    }

    async getByQuery(maand: number, jaar: number): Promise<Array<IFotoVDMaand>> {
        const queryTerm = query(ref, where('maand', '==', maand), where('jaar', '==', jaar));
        const snapshot = await getDocs(queryTerm);
        const data: Array<any> = [];
        snapshot.docs.forEach((event) => {
            return data.push({
                id: event.id,
                ...event.data(),
            });
        });
        return data as Array<IFotoVDMaand>;
    };

    async updateField(id: string, key: string, value: any): Promise<any> {
        let input = value;
        if ((key === "maand" || key === "jaar") && typeof value === "string") input = parseInt(value)
        const eventRef = doc(db, Collections.fotovdmaand, id);
        return await updateDoc(eventRef, {
            [key]: input
        });
    }

    async deleteFotoVDMaand(key: any) {
        await deleteDoc(doc(db, "fotovdmaand", key));
    }
}

const maandFotoService = new MaandFotoService();
export default maandFotoService;