import React, {useContext, useState} from 'react';
import "./AdminPage.css"
import KalenderAdmin from "../kalenderAdmin/KalenderAdmin";
import AdminMenu from "../adminMenu/AdminMenu";
import {AuthContext} from "../../../context/AuthContext";
import Login from "../../../pages/login/Login";
import EventList from "../eventList/EventList";
import CreateEvent from "../createEvent/CreateEvent";
import FotoVDMaand from "../maandFotoAdmin/FotoVDMaand";
import {AdminMenuItems} from "../../../utils/CONSTANTS";

function AdminPage() {
    const {currentUser} = useContext(AuthContext)
    const [selected, setSelected] = useState<string>("");
    if (!currentUser) return (<Login/>)
    switch (selected) {
        case AdminMenuItems.ManageCalendar:
            return (<>
                <AdminMenu setSelected={setSelected}/>
                <KalenderAdmin/>
            </>);
        case AdminMenuItems.ManageEvents:
            return (<>
                <AdminMenu setSelected={setSelected}/>
                <EventList/>
            </>);
        case AdminMenuItems.CreateEvent:
            return (<>
                <AdminMenu setSelected={setSelected}/>
                <CreateEvent/>
            </>);
        case AdminMenuItems.ManageMonthPhoto:
            return (<>
                <AdminMenu setSelected={setSelected}/>
                <FotoVDMaand/>
            </>);
        default:
            return (
                <AdminMenu setSelected={setSelected}/>
            );
    }
}

export default AdminPage;
