import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/home/Home";
import Contact from "./pages/contact/Contact";
import Kalender from "./pages/kalender/Kalender";
import Clubtochten from "./pages/clubtochten/Clubtochten";
import Bondsfotowedstrijd from "./pages/bondsfotowedstrijd/Bondsfotowedstrijd";
import Exposities from "./pages/expositie/Exposities";
import DBC from "./pages/dbc/DBC";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import Galerij from "./pages/galerij/Galerij";
import {AuthProvider} from "./context/AuthContext";
import AdminPage from "./components/adminSection/adminPage/AdminPage";
import PersoonlijkeSuccessen from "./pages/persoonlijkeSuccessen/PersoonlijkeSuccessen";
import ClubSuccessen from "./pages/clubSuccessen/ClubSuccessen";
import Nieuws from "./pages/nieuws/Nieuws";
import Info from "./pages/info/Info";
import {DataProvider} from "./context/DataContext";

function App() {
    return (
        <DataProvider>
            <BrowserRouter>
                <ScrollToTop/>
                <AuthProvider>
                    <Routes>
                        <Route path='*' element={<Home/>}/>
                        <Route path="/" element={<Home/>}/>
                        <Route path='/info' element={<Info/>}/>
                        <Route path='/nieuws' element={<Nieuws/>}/>
                        <Route path='/expositie' element={<Exposities/>}/>
                        <Route path='/bondsfotowedstrijd' element={<Bondsfotowedstrijd/>}/>
                        <Route path='/debesteclub' element={<DBC/>}/>
                        <Route path='/clubtochten' element={<Clubtochten/>}/>
                        <Route path='/clubsuccessen' element={<ClubSuccessen/>}/>
                        <Route path='/persoonlijkesuccessen' element={<PersoonlijkeSuccessen/>}/>
                        <Route path='/agenda' element={<Kalender/>}/>
                        <Route path='/contact' element={<Contact/>}/>
                        <Route path={"/clubtochten/:id"} element={<Galerij/>}/>
                        <Route path={"/bondsfotowedstrijd/:id"} element={<Galerij/>}/>
                        <Route path={"/expositie/:id"} element={<Galerij/>}/>
                        <Route path={"/debesteclub/:id"} element={<Galerij/>}/>
                        <Route path={"/persoonlijkesuccessen/:id"} element={<Galerij/>}/>
                        <Route path={"/clubsuccessen/:id"} element={<Galerij/>}/>
                        <Route path={"/nieuws/:id"} element={<Galerij/>}/>
                        <Route path={"/admin"} element={<AdminPage/>}/>
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
        </DataProvider>
    );
}

export default App;