import React from 'react';
import "./AgendaItem.css";
import {Link} from "react-router-dom";

interface AgendaItemProps {
    soort: string;
    datum: string;
    tijd: string;
    programma?: string;
    bijzonderheden?: string;
    link?: boolean;
}


function AgendaItem({soort, tijd, programma, bijzonderheden, datum, link}: AgendaItemProps) {

    return (
        <div className={"agenda-item"}>
            <div className={"soort"}>{soort}</div>
            <div className={"datum"}>{datum}</div>
            <div className={"tijd"}>{tijd}</div>
            <div className={"programma"}>{programma}</div>
            <div className={"bijzonderheden"}>{bijzonderheden} {link && <Link to={"/contact"}>Aanmelden</Link>}</div>
        </div>
    );
}

export default AgendaItem;