import {ChangeEvent} from "react";

export function handleChangeOfState(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, stateToChange: any): void {
    const {name, type} = e.target as HTMLInputElement;
    const value = type === 'checkbox' ? (e.target as HTMLInputElement).checked : (e.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement).value;

    if(e.target.name === "soort") {
        if(e.target.value === "Workshop") {
            stateToChange((prevEvent: any) => ({
                ...prevEvent,
                van: "19:30",
                tot: "20:00"
            }))
        }
        if(e.target.value === "Clubavond") {
            stateToChange((prevEvent: any) => ({
                ...prevEvent,
                van: "20:00",
                tot: "22:00"
            }))
        }
    }
    console.log(e.target.value)
    stateToChange((prevEvent: any) => ({
        ...prevEvent,
        [name]: value
    }))
}