import React, {useEffect} from 'react';
import "./Hero.css";
import info from "../../assets/Information-Icon.svg";
import IFotoVDMaand from "../../models/fotovdmaand";
import MaandFotoService from "../../services/MaandFotoService";
import BackupHero from "../../assets/backupHero.jpg";
import Loader from "../../assets/loader.gif"

function Hero() {
    const date = new Date();
    const maand = date.getMonth() + 1;
    const jaar = date.getFullYear();
    const [photos, setPhotos] = React.useState<IFotoVDMaand[]>([]);

    function getFotoVdMaand(maand: number, jaar: number) {
        MaandFotoService
            .getByQuery(maand, jaar)
            .then((res) => {
                if (res && res.length > 0) {
                    setPhotos(res);
                } else {
                    setPhotos([{
                        id: "",
                        createdAt: "automated",
                        createdBy: "automated",
                        maand: 0,
                        jaar: 0,
                        alt: "Abstract",
                        fotograaf: "Connie Soeteman",
                        link: BackupHero
                    }])
                }
            })
            .catch(e => console.error(e))
    }

    useEffect(() => {
        getFotoVdMaand(maand, jaar);
    }, [maand, jaar]);


    return (
        <>
            {photos.length > 0 ?
                <div id="hero">
                    <div className="maand-info">
                        <img src={info} alt="I-button"/>
                        <h2>Foto van de Maand</h2>
                        <div className="fotograaf"><span>Gemaakt door:</span>{` ${photos[0]?.fotograaf}.`}</div>
                        <div className="info-specs">
                            {`${photos[0]?.alt}`}
                        </div>
                    </div>
                    <div className='hero-image-container'>
                        <img src={photos[0]?.link} alt={photos[0]?.alt}/>
                    </div>
                </div>
                :
                <div id="hero" style={{backgroundColor: "white"}}>
                    <img src={Loader} alt={"Loading...."}/>
                </div>
            }
        </>
    );
}

export default Hero;