import Spacer from "../spacer/Spacer";
import './FormComponent.css';
import {ReactNode, RefObject} from "react";

interface FormProps {
    title: string;
    form: RefObject<HTMLFormElement>;
    isSubmitted: boolean;
    setIsSubmitted: (value: boolean) => void;
    children: ReactNode;
    submitAction: (event: any) => void;
}

function FormComponent({title, form, isSubmitted, setIsSubmitted, children, submitAction}: FormProps) {
    return (
            <div className="spacer-form-container">
                <Spacer/>
                <div className="contact-form-container">
                    <h2>{title}</h2>
                    <div className="contact-form">
                        <form ref={form} onSubmit={submitAction} className="form">
                            {children}
                        </form>
                    </div>
                </div>
                <Spacer/>
            </div>
    );
}

export default FormComponent;