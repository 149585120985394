import React from 'react';
import './ButtonComponent.css';

interface ButtonProps {
    message: string;
    type: 'submit' | 'button';
    onClick?: () => void;
}

function ButtonComponent({message, type, onClick}: ButtonProps) {
    if(type === "submit") {
        return (
            <button type="submit" value="Send" className={'btn'}>
                {message}
            </button>
        );
    } else {
        return (
            <button type="button" onClick={onClick} className={'btn'}>
                {message}
            </button>
        );
    }
}

export default ButtonComponent;