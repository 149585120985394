import React, {useState} from 'react';
import "./CreateEvent.css"
import IEvent from "../../../models/eventItem";
import Preview from "../../preview/Preview";
import {isValidImgUrl} from "../../../utils/helpers/validators";
import EventService from "../../../services/EventService";
import {FaRegTrashAlt} from "react-icons/fa";
import {MdOutlineDescription, MdOutlineLink} from "react-icons/md";
import {Alerts, Confirms, EventSoort, Prompts} from "../../../utils/CONSTANTS";
import {handleChangeOfState} from "../../../utils/helpers/test";

function CreateEvent() {
    const dummyImage = "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081"
    const [photo, setPhoto] = React.useState({link: "", alt: ""});
    const [newEvent, setNewEvent] = React.useState<IEvent>({
        beschrijving: "",
        createdAt: "",
        createdBy: "",
        datum: "",
        hero: "",
        id: "",
        locatie: "",
        photos: [],
        soort: "",
        title: "",
        visible: true
    });
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const areAllFieldsFilled = (newEvent.soort !== "") && (newEvent.title !== "") && (newEvent.beschrijving !== "" && (newEvent.datum !== "") && (newEvent.photos.length > 0))

    const handleMouseEnter = (index: any) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const handleDelete = (index: any) => {
        if (window.confirm(Confirms.DeletePicture)) {
            setNewEvent((prevEvent) => ({
                ...prevEvent,
                photos: prevEvent.photos.filter((_, i) => i !== index),
            }));
        }
        setHoveredIndex(null);
    };

    function resetForm() {
        setNewEvent({
            beschrijving: "",
            createdAt: "",
            createdBy: "",
            datum: "",
            hero: "",
            id: "",
            locatie: "",
            photos: [],
            soort: "",
            title: "",
            visible: true
        })
    }

    function createEvent(event: IEvent) {
        EventService
            .create(event)
            .then(() => alert("Het event is succesvol aangemaakt"))
            .catch(e => console.error(e))
            .finally(() => resetForm());
    }

    function addPhoto() {
        const double = newEvent.photos.find((item) => item.link === photo.link)
        const wrong = !isValidImgUrl(photo.link)
        if (double) {
            alert(Alerts.DoublePhoto)
        } else if (wrong) {
            alert(Alerts.InvalidUrl)
        } else {
            setNewEvent({...newEvent, photos: [...newEvent.photos, photo]})
        }
        setPhoto({link: "", alt: ""})
    }

    function givePromptForAltChange(index: number, current: string) {
        const newAlt = prompt(Prompts.NewAlt, current)
        if (newAlt) {
            setNewEvent((prevEvent) => ({
                ...prevEvent,
                photos: prevEvent.photos.map((photo, i) => {
                    if (i === index) {
                        return {...photo, alt: newAlt}
                    } else {
                        return photo
                    }
                })
            }))
        }
    }

    async function givePromptForLinkChange(index: number, current: string) {
        const link = prompt(Prompts.NewImgUrl, current)
        if (!link) return;
        if (link && await isValidImgUrl(link)) {
            setNewEvent((prevEvent) => ({
                ...prevEvent,
                photos: prevEvent.photos.map((photo, i) => {
                    if (i === index) {
                        return {...photo, link: link}
                    } else {
                        return photo
                    }
                })
            }))
        } else {
            alert(Alerts.InvalidUrl)
        }
    }

    return (
        <div className={"eventA-container"}>
            <h2>Maak een event aan</h2>
            <div className={"eventA-form"}>
                <select name={"soort"}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleChangeOfState(e, setNewEvent)}>
                    <option style={{display: "none"}}>Soort event</option>
                    {(Object.keys(EventSoort) as Array<keyof typeof EventSoort>).map((key) => {
                        return (
                            <option value={EventSoort[key].valueOf()}
                                    key={EventSoort[key].valueOf()}>{EventSoort[key]}</option>
                        );
                    })}
                </select>
                <input type={"text"}
                       value={newEvent.datum}
                       name={"datum"}
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeOfState(e, setNewEvent)}
                       placeholder={"Vul hier de datum in volgens formaat DD-MM-YYYY"}/>
                <input type={"text"}
                       value={newEvent.title}
                       name={"title"}
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeOfState(e, setNewEvent)}
                       placeholder={"Vul hier de titel in"}/>
                <input type={"text"}
                       value={newEvent.locatie}
                       name={"locatie"}
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeOfState(e, setNewEvent)}
                       placeholder={"Indien van toepassing: Vul hier de locatie van het eventItem in"}/>
                <textarea name={"beschrijving"}
                          value={newEvent.beschrijving}
                          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleChangeOfState(e, setNewEvent)}
                          placeholder={"Geef hier een beschrijving van het eventItem"}/>
            </div>
            <div className={"eventA-preview"}>
                <h2>Preview</h2>
                <Preview
                    title={newEvent.title ? newEvent.title : "De titel van het eventItem dat je gaat aanmaken"}
                    beschrijving={newEvent.beschrijving ? newEvent.beschrijving : "Geef hier een beschrijving van het eventItem"}
                    img={newEvent?.photos.length > 0 ? newEvent.photos[0].link : dummyImage}
                    id={newEvent.id}
                    orientation={"landscape"}
                    soort={newEvent.soort}/>
            </div>
            <div className={"eventA-overview"}>
                <input type={"url"}
                       value={photo.link}
                       name={"link"}
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeOfState(e, setPhoto)}
                       placeholder={"Voer hier het url in van de foto de je wilt toevoegen"}/>
                <input type={"text"}
                       value={photo.alt}
                       name={"alt"}
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeOfState(e, setPhoto)}
                       placeholder={"Voer hier de beschrijving in van de foto de je wilt toevoegen"}/>
                <button disabled={photo.alt === "" || photo.link === ""} type={"button"} onClick={addPhoto}>Voeg
                    foto toe
                </button>
                <div id={"foto-overzicht"}>
                    {newEvent?.photos?.map(({link, alt}, index) => {
                        return (
                            <div className={'foto-container-createEvent'}
                                 id={`${index}`}
                                 key={index}
                                 onMouseEnter={() => handleMouseEnter(index)}
                                 onMouseLeave={handleMouseLeave}>
                                <img src={link}
                                     alt={alt}
                                     className={"foto"}/>
                                {hoveredIndex === index && (
                                    <>
                                        <span className={'buttons'}>
                                            <button
                                                onClick={() => givePromptForAltChange(index, alt)}><MdOutlineDescription/></button>
                                            <button
                                                onClick={() => givePromptForLinkChange(index, link)}><MdOutlineLink/></button>
                                        </span>
                                        <button className={'delete-button'} onClick={() => handleDelete(index)}>
                                            <FaRegTrashAlt/></button>
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            <button disabled={!areAllFieldsFilled} onClick={() => createEvent(newEvent)}>
                Maak event
            </button>
        </div>
    );
}

export default CreateEvent;