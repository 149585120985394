import React from 'react';
import './InputComponent.css';

interface InputProps {
    id: string;
    type: string;
    placeholder: string;
    required: boolean;
}
function InputComponent({id, type, placeholder, required}: InputProps) {
    if (type === "textarea") {
        return (
            <textarea
                id="message"
                placeholder="Schrijf hier je bericht aan ons"
                name="message"
                className="input-field"
                required
            />
        )
    } else {
        return (
            <input
                type={type}
                id={id}
                name={id}
                placeholder={placeholder}
                className="input-field"
                required={required}
            />
        );
    }
}

export default InputComponent;