import React, {useContext, useRef, useState} from 'react';
import './Contact.css';
import Header from "../../components/headerComponent/HeaderComponent";
import Form from "../../components/formComponent/FormComponent";
import Input from "../../components/inputComponent/InputComponent";
import Button from "../../components/buttonComponent/ButtonComponent";
import {useNavigate} from "react-router-dom";
import FooterComponent from "../../components/footerComponent/FooterComponent";
import {rightMouseBlocker} from "../../utils/helpers/utils";
import {DataContext} from "../../context/DataContext";
import MailService from "../../services/MailService";


function Contact() {
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [selectedSubject, setSelectedSubject] = useState<string>("")
    const form: any = useRef<HTMLFormElement>(null);
    const navigate = useNavigate();

    function sendEmail(e: any): void {
        e.preventDefault();
        MailService
            .sendEmail(form)
            .then((): void => {
                setIsSubmitted(!isSubmitted)
                setTimeout(() => {
                    navigate('/')
                }, 5000)
            })
            .catch((e: any) => console.error(e));
    }

    function handleSelect<T>(e: React.ChangeEvent<HTMLSelectElement>, setState: React.Dispatch<React.SetStateAction<T>>) {
        setState(e.target.value as T);
    }

    function DateSelector() {
        const {sortedAgendaItems} = useContext(DataContext);
        const workshops = sortedAgendaItems?.filter(item => item.soort === "Workshop") || [];

        return (
            <>
                het is mogelijk meerdere opties te selecteren
                <select id="workshop"
                        className="form_select_multiple"
                        name="workshop_datum"
                        multiple={true}>
                    <option style={{display: "none"}}>Kies een of meerdere onderwerpen</option>
                    {workshops.length > 0 ? (
                        workshops.map((workshop, index) => (
                            <option key={workshop.datum + index} value={workshop.datum + " " + workshop.programma}>
                                {workshop.datum.split("-").reverse().join("-")} {workshop.programma}
                            </option>
                        ))
                    ) : (
                        <option value="Informeer me als er nieuwe data bekend zijn">
                            Informeer me als er nieuwe data bekend zijn
                        </option>
                    )}
                </select>
            </>
        );
    }

    function QuestionSelect() {
        return (
            <>
                het is mogelijk meerdere opties te selecteren
                <select id="vraag"
                        className="form_select_multiple"
                        name="onderwerp_vraag"
                        multiple={true}>
                    <option value="lightroom">Lightroom</option>
                    <option value="photoshop">Photoshop</option>
                    <option value="belichting">Belichting</option>
                    <option value="basisbewerking">Basisbewerking</option>
                    <option value="camerastanden">Camerastanden</option>
                    <option value="witbalans">Witbalans</option>
                    <option value="scherpstelling">Scherpstelling</option>
                    <option value="compositie">Compositie</option>
                </select>
            </>
        )
    }

    return (
        <>
            <Header/>
            <div className={`contact-container`} onContextMenu={rightMouseBlocker}>
                <section className={`form-section`}>
                    {!isSubmitted ?
                        <Form form={form}
                              submitAction={sendEmail}
                              title="Neem contact met ons op"
                              isSubmitted={isSubmitted}
                              setIsSubmitted={setIsSubmitted}>
                            <Input
                                id="user_name"
                                type="text"
                                placeholder="Vul hier je naam in"
                                required={true}/>
                            <Input
                                id="user_email"
                                type="email"
                                placeholder="Vul hier je emailadres in"
                                required={true}/>
                            <select
                                className="form_select"
                                id="user_subject"
                                required={true}
                                name="user_subject"
                                onChange={(e) => handleSelect(e, setSelectedSubject)}>
                                <option style={{display: "none"}}>Onderwerp</option>
                                <option value="vraag">Ik heb een vraag</option>
                                <option value="ik wil graag meer leren over">Ik wil graag meer leren over</option>
                                <option value="aanmelden workshop">Ik wil me aanmelden voor de instructie avond</option>
                                <option value="aanmelden fotoclub">Ik wil lid worden van de fotoclub</option>
                            </select>
                            {selectedSubject === "ik wil graag meer leren over"
                                &&
                                <QuestionSelect/>
                            }
                            {selectedSubject === "aanmelden workshop"
                                &&
                                <DateSelector/>
                            }
                            {selectedSubject === "vraag"
                                &&
                                <Input id="message"
                                       type="textarea"
                                       placeholder="Schrijf hier je bericht aan ons"
                                       required={true}/>
                            }
                            <Button
                                type={"submit"}
                                message="Versturen"/>
                        </Form>
                        :
                        <p>Je bericht is verstuurd! Dankjewel!</p>
                    }
                </section>

                <section className={`whereToFind-section`}>
                    <h2>Route</h2>
                    <article>
                        onze bijeenkomsten zijn in<br/>
                        Wijkcentrum Lopes Dias<br/>
                        Lopes Diaslaan 213<br/>
                        1222 VE Hilversum
                    </article>
                    <iframe
                        title={`whereToFind`}
                        src={"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4886.2026720722515!2d5.179591000000001!3d52.241543!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c66b4e1624c3bb%3A0x6655cb80e60622c0!2sWoondienstencentrum%20Lopes%20Dias!5e0!3m2!1snl!2snl!4v1692459524624!5m2!1snl!2snl"}
                        width="400"
                        height="300"
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                    <a href={"https://www.google.com/maps/place/Woondienstencentrum+Lopes+Dias/@52.241335,5.178484,15z/data=!4m6!3m5!1s0x47c66b4e1624c3bb:0x6655cb80e60622c0!8m2!3d52.2413345!4d5.1784836!16s%2Fg%2F11cn3v2459?hl=nl&entry=ttu"}
                       target={"_blank"} rel={"noopener noreferrer"}>ROUTE</a>
                </section>
            </div>
            <FooterComponent/>
        </>
    );
}

export default Contact;