import React, {useState} from 'react';
import "./Preview.css"
import PropTypes, {InferProps} from 'prop-types';
import {Link} from "react-router-dom";
import MyModal from "../modal/MyModal";

Preview.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    img: PropTypes.string,
    orientation: PropTypes.string,
    beschrijving: PropTypes.string,
    soort: PropTypes.string.isRequired,
};
function Preview({id, title, beschrijving, img, soort}: InferProps<typeof Preview.propTypes>) {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    let soortenZonderKnop: boolean = soort === "Actueel" || soort === "Persoonlijkesuccessen";

    return (
        <>
            <div>
                <MyModal isOpen={modalIsOpen} onRequestClose={closeModal}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        height: '100%'
                    }}>
                        <h2>{title}</h2>
                        <div id={"div"} style={{width: "50%", whiteSpace: "pre-wrap"}}>
                            {beschrijving}
                        </div>
                        <img src={img as string} alt={beschrijving as string}
                             style={{objectFit: "contain", width: '90%', height: '90%'}}
                             onClick={closeModal}/>
                        <br/>
                        <br/>
                        <button style={{ marginBottom: '50px' }} className={'btn'} onClick={closeModal}>
                            Sluiten
                        </button>
                    </div>
                </MyModal>
            </div>
            <article className={`preview-container`}>
                <section className="preview">
                    {img &&
                        <img src={img as string}
                             alt={beschrijving as string}/>}
                    <div className="image-overlay"></div>
                </section>
                <hr/>
                <section className="info">
                    <h2>{title ?? beschrijving}</h2>
                    <p>{beschrijving}</p>
                    {!soortenZonderKnop ?
                        <Link to={id}>
                            <button className="btn">Bekijk de foto's</button>
                        </Link>
                        :
                        <button className="btn" onClick={openModal}>Bekijk de foto</button>
                    }
                </section>
            </article>
        </>
    );


}

export default Preview;