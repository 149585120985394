import {auth, db} from "../config/firebase";
import IKalenderItem from "../models/kalenderItem";
import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where,} from 'firebase/firestore';
import {Collections} from "../utils/CONSTANTS";

const ref = collection(db, Collections.calendar)

class CalendarService {

    async getAll(): Promise<Array<IKalenderItem>> {
        const snapshot = await getDocs(ref)
        const data: Array<any> = [];
        snapshot.docs.forEach((item) => {
            return data.push({
                id: item.id,
                ...item.data(),
            });
        });
        return data as Array<IKalenderItem>;
    };

    async create(item: IKalenderItem): Promise<any> {
        return await addDoc(collection(db, "calendar"), {
            datum: item.datum,
            soort: item.soort,
            programma: item.programma,
            geweest: item.geweest,
            link: item.link,
            tijd: item.tijd,
            van: item.van,
            tot: item.tot,
            bijzonderheden: item.bijzonderheden,
            createdBy: auth?.currentUser?.uid,
            createdAt: Date.now(),
        });
    }

    async getFutureEvents(): Promise<Array<IKalenderItem>> {
        const queryTerm = query(ref, where('geweest', "==", false));
        const snapshot = await getDocs(queryTerm);
        const data: Array<any> = [];
        snapshot.docs.forEach((event) => {
            return data.push({
                id: event.id,
                ...event.data(),
            });
        });
        return data as Array<IKalenderItem>;
    };

    async getOne(id: string): Promise<any> {
        const eventRef = doc(db, 'calendar', id);
        return await getDoc(eventRef)
    }

    async updateItem(id: string, item: IKalenderItem): Promise<any> {
        const eventRef = doc(db, 'calendar', id);
        return await updateDoc(eventRef, {
            "soort" : item.soort,
            "datum": item.datum,
            "programma": item.programma,
            "bijzonderheden": item.bijzonderheden,
            "link": item.link,
            "tijd": `${item.van}-${item.tot}`,
            "van": item.van,
            "tot": item.tot
        });
    }

    async setIsGeweest(id: string): Promise<any> {
        const eventRef = doc(db, 'calendar', id);
        return await updateDoc(eventRef, {
            geweest: true
        });
    }

    async deleteCalendarItem(key: any) {
        await deleteDoc(doc(db, "calendar", key));
    }
}

const calendarService = new CalendarService();
export default calendarService;