import React, {useEffect, useState} from 'react';
import EventService from "../../../services/EventService";
import IEvent from "../../../models/eventItem";
import "./EventList.css";
import Event from "../eventItem/EventItem";
import {sortEventsDesc} from "../../../utils/helpers/sorters";
import {Confirms, EventSoort} from "../../../utils/CONSTANTS";

function EventList() {
    const [events, setEvents] = useState<Array<IEvent>>([]);
    const [filteredEvents, setFilteredEvents] = useState<Array<IEvent>>([]);

    function getAllEvents() {
        EventService.getAll()
            .then((res) => {
                setEvents(res)
                setFilteredEvents(res)
            })
            .catch(e => console.error(e))
    }

    useEffect(() => {
        getAllEvents();
    }, []);

    function handleDelete(id: string) {
        if (window.confirm(Confirms.DeleteEvent)) {
            EventService
                .deleteEvent(id)
                .then(() => setFilteredEvents(filteredEvents.filter((event) => event.id !== id)))
                .then(() => setEvents(events.filter((event) => event.id !== id)))
                .catch(e => console.error(e))
        }
    }

    function performAction(e: string) {
        setFilteredEvents(events);
        setFilteredEvents(events.filter((event) => event.soort === e));
    }


    return (
        <div className={"event-container"}>
            <div>
                <select name="filter" id="filter" onChange={(e) => performAction(e.target.value)}>
                    <option style={{display: "none"}}>Soort event</option>
                    {(Object.keys(EventSoort) as Array<keyof typeof EventSoort>).map((key) => {
                        return (
                            <option value={EventSoort[key].valueOf()} key={EventSoort[key].valueOf()}>{EventSoort[key]}</option>
                        );
                    })}
                </select>

                <button onClick={() => setFilteredEvents(events)}>Toon alles</button>
            </div>
            {events && filteredEvents && sortEventsDesc(filteredEvents).map((event) => {
                return (
                    <Event key={event.id} eventInput={event} delete={handleDelete}/>
                )
            })}
        </div>
    );
}

export default EventList;