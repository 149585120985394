import {User} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {createContext, ReactNode, SetStateAction, useEffect, useState} from "react";
import {SignOutUser, userStateListener} from "../config/firebase";

interface Props {
    children?: ReactNode
}

export const AuthContext = createContext({
    currentUser: {} as User | null,
    setCurrentUser: (_user: User) => {},
    signOut: () => {}
});

export const AuthProvider = ({children}: Props) => {
    document.addEventListener('contextmenu', function (e) {
        e.preventDefault();
    });
    const [currentUser, setCurrentUser] = useState<User | null>(null)
    const navigate = useNavigate()

    useEffect(() => {
        return userStateListener((user: SetStateAction<User | null>) => {
            if (user) {
                setCurrentUser(user)
            }
        })
    }, [setCurrentUser]);

    const signOut = () => {
        SignOutUser()
            .then(() => setCurrentUser(null))
            .catch((error) => console.error(error))
            .finally(() => navigate('/'))
    }

    const value = {
        currentUser,
        setCurrentUser,
        signOut
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
  