export const isValidImgExtInUrl = (url: string) => {
    const imageUrlRegex = /\.(jpeg|jpg|gif|png)$/i;
    return imageUrlRegex.test(url);
}

export async function isValidImgUrl(url: RequestInfo | URL) {
    const res = await fetch(url);
    const buff = await res.blob();
    return buff.type.startsWith('image/');
}


export function isValidImgUrl3(url: string): boolean {
    let image = new Image();
    image.src = url;
    return image.height > 0;
}

export const inFuture = (stringDate: string) => {
    const date = new Date(stringDate);
    return date.getTime() > Date.now();
}

export const isValidDate = (stringDate: string) => {
    const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
    return dateRegex.test(stringDate);
}
