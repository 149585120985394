import React, {useContext, useEffect, useState} from 'react';
import Preview from "../preview/Preview";
import IEvent from "../../models/eventItem";
import {sortEventsDesc} from "../../utils/helpers/sorters";
import {EventSoort, PageInfo} from "../../utils/CONSTANTS";
import "./EventOverview.css";
import {getRandomHero} from "../../utils/helpers/heroPicker";
import {useNavigate} from "react-router-dom";
import {DataContext} from "../../context/DataContext";

interface EventOverviewProps {
    soort: EventSoort;
}

function EventOverview({soort}: Readonly<EventOverviewProps>) {
    const navigate = useNavigate();
    const {allEvents} = useContext(DataContext);
    const selectedEvents = filterEventsByType(allEvents, soort);

    const [visibleCount, setVisibleCount] = useState(5);
    const [pageInfo, setPageInfo] = useState({
        beschrijving: [""],
        titel: ""
    });

    function filterEventsByType(events: IEvent[], type: EventSoort): IEvent[] {
        return events.filter(({visible, soort}) => visible && soort === type);
    }

    useEffect(() => {
        getPageInfo(soort);
    }, [soort]);

    function getPageInfo(type: EventSoort): void {
        switch (type) {
            case EventSoort.Clubtocht:
                setPageInfo(PageInfo.clubtochten);
                break;
            case EventSoort.Expositie:
                setPageInfo(PageInfo.exposities);
                break;
            case EventSoort.DeBesteClub:
                setPageInfo(PageInfo.debesteclub);
                break;
            case EventSoort.Bondsfotowedstrijd:
                setPageInfo(PageInfo.bondsfotowedstrijd);
                break;
            case EventSoort.PersoonlijkeSuccessen:
                setPageInfo(PageInfo.Persoonlijkesuccessen);
                break;
        }
    }

    const handleShowMore = () => {
        setVisibleCount((prevCount) => prevCount + 5);
    };

    return (
        <div id={"eventOverview-container"}>
            <section id="event-info">
                <h2>{pageInfo.titel}</h2>
                {pageInfo.beschrijving.map(((line: string, index: number) => <p key={line + index}>{line}</p>))}
            </section>
            {selectedEvents.length > 0 ?
                <>
                    <section className="overview-container">
                        {sortEventsDesc(selectedEvents)
                            .slice(0, visibleCount)
                            .map((event: IEvent, index: number) => {
                                return (
                                    <Preview
                                        id={event.id}
                                        title={event.title}
                                        img={getRandomHero(event)}
                                        beschrijving={event.beschrijving}
                                        key={event.id}
                                        soort={event.soort}
                                    />
                                );
                            })}
                    </section>
                    {visibleCount < selectedEvents.length &&
                        (
                            <button className={'btn'} onClick={handleShowMore}>Toon meer</button>
                        )
                    }
                    <button className={'btn'} onClick={() => navigate(-1)}>Terug</button>
                </>
                : <h2>Er zijn nog geen events in deze categorie</h2>}
        </div>
    );
}

export default EventOverview;
