import React, {useContext, useState} from 'react';
import "./Gallerij.css";
import {useNavigate, useParams} from "react-router-dom";
import HeaderComponent from "../../components/headerComponent/HeaderComponent";
import FooterComponent from "../../components/footerComponent/FooterComponent";
import IEvent from "../../models/eventItem";
import {rightMouseBlocker} from "../../utils/helpers/utils";
import {DataContext} from "../../context/DataContext";

function Galerij() {
    const {allEvents} = useContext(DataContext);
    const {id} = useParams();
    const [carousel, toggleCarousel] = useState<boolean>(false);
    const [highlightedImg, setHighlightedImg] = useState<number>(0);
    const element = document.getElementById(`${highlightedImg}`);
    const navigate = useNavigate();

    const currentEvent: IEvent = allEvents.find((event) => event.id === id) as IEvent;

    function handleCarouselInteraction(e: React.MouseEvent<HTMLImageElement>) {
        if (window.innerWidth >= 992) {
            for (let i = 0; i < currentEvent.photos.length; i++) {
                if (currentEvent.photos[i].alt === e.currentTarget.getAttribute("alt")) {
                    setHighlightedImg(i);
                }
            }
            if (!carousel) {
                toggleCarousel(true);
            }
        }
    }

    return (
        <div id={"galerij-container"} onContextMenu={rightMouseBlocker}>
            {!carousel && <HeaderComponent/>}
            {!carousel && <div className={"galerij-info"}>
                <h2>{currentEvent.title}</h2>
                <p>{currentEvent.beschrijving}</p>
            </div>}
            {carousel && <div id="carousel">
                <div id="carousel-btn-next" onClick={() => {

                    if (highlightedImg === currentEvent.photos.length - 1) {
                        setHighlightedImg(0);
                        element?.scrollIntoView({behavior: "smooth"});
                    } else {
                        setHighlightedImg(highlightedImg + 1);
                        element?.scrollIntoView({behavior: "smooth"});
                    }
                }}>{`>`}</div>
                <div id="carousel-btn-prev" onClick={() => {
                    if (highlightedImg === 0) {
                        setHighlightedImg(currentEvent.photos.length - 1);
                        element?.scrollIntoView({behavior: "smooth"});

                    } else {
                        setHighlightedImg(highlightedImg - 1);
                        element?.scrollIntoView({behavior: "smooth"});

                    }
                }}>{`<`}</div>
                <div id="carousel-btn-close" onClick={() => toggleCarousel(false)}>{`✖`}</div>
                <img src={currentEvent.photos[highlightedImg].link} alt={currentEvent.photos[highlightedImg].alt}/>
            </div>}
            <div id={carousel ? "foto-overzicht-flat" : "foto-overzicht"}>
                {currentEvent?.photos?.map((foto, index) => {
                    return (
                        <div className={"foto-container"}
                             id={`${index}`}
                             key={foto.link}>
                            <img src={foto?.link}
                                 alt={foto?.alt}
                                 onClick={(e: React.MouseEvent<HTMLImageElement>) => handleCarouselInteraction(e)}/>
                        </div>
                    );
                })}
            </div>
            {!carousel &&
                <>
                    <button className={'btn'} onClick={() => navigate(-1)}>Terug</button>
                    <FooterComponent/>
                </>
            }
        </div>
    );
}

export default Galerij;