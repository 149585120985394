import {auth, db} from "../config/firebase";

import {addDoc, collection, deleteDoc, doc, getDocs, updateDoc} from 'firebase/firestore';

import IEvent from "../models/eventItem";
import {Collections} from "../utils/CONSTANTS";

const ref = collection(db, Collections.events)

class EventService {

    async getAll(): Promise<Array<IEvent>> {
        const snapshot = await getDocs(ref)
        const data: Array<any> = [];
        snapshot.docs.forEach((item) => {
            return data.push({
                id: item.id,
                ...item.data(),
            });
        });
        return data as Array<IEvent>;
    };

    async create(item: any): Promise<any> {
        return await addDoc(collection(db, "events"), {
            datum: item.datum,
            soort: item.soort,
            locatie: item.locatie,
            title: item.title,
            beschrijving: item.beschrijving,
            hero: item.hero,
            photos: item.photos,
            createdBy: auth?.currentUser?.uid,
            createdAt: Date.now(),
        });
    }

    async update(id: string, key: string, value: any): Promise<any> {
        const eventRef = doc(db, 'events', id);
        return await updateDoc(eventRef, {
            [key]: value
        });
    }

    async deleteEvent(key: any) {
        await deleteDoc(doc(db, "events", key));
    }

}

const eventService = new EventService();
export default eventService;