import React, {useEffect} from 'react';
import Modal from 'react-modal';
import ModalProps from '../../models/modalProps';

Modal.setAppElement('#root');

function MyModal({isOpen, onRequestClose, children}: ModalProps) {

    useEffect(() => {
        if (isOpen) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "auto";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            style={{
                content: {
                    padding: '20px'
                }
            }}
            onRequestClose={onRequestClose}
        >
            {children}
        </Modal>
    );
}

export default MyModal;