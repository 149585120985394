import React, {createContext, useEffect, useMemo, useState} from 'react';
import EventService from '../services/EventService';
import IEvent from '../models/eventItem';
import IKalenderItem from "../models/kalenderItem";
import {geweest, SortAndFilterAgendaItems} from "../utils/helpers/sorters";
import calendarService from "../services/CalendarService";

export const DataContext = createContext<{
    allEvents: IEvent[];
    sortedAgendaItems: IKalenderItem[];
    nextMeeting: string;
}>({
    allEvents: [],
    sortedAgendaItems: [],
    nextMeeting: "",
});

interface DataProviderProps {
    children: React.ReactNode;
}

export function DataProvider({children}: Readonly<DataProviderProps>) {
    const [events, setEvents] = useState<Array<IEvent>>([]);
    const [agendaItems, setAgendaItems] = useState<Array<IKalenderItem>>([])
    const sortedAgendaItems = SortAndFilterAgendaItems(agendaItems)

    const siteData = useMemo(() => {
        return {
            allEvents: events,
            sortedAgendaItems: sortedAgendaItems,
            nextMeeting: getAndValidateNextMeeting()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortedAgendaItems, events]);

    function getAndValidateNextMeeting() {
        let nextEvent = sortedAgendaItems.find((item) => !geweest(item.datum));
        if (!nextEvent) return "Info volgt z.s.m.";
        let inputDate = new Date(nextEvent.datum + "T" + nextEvent.van);
        let today = new Date();
        if (inputDate === today) return "Vandaag"
        if (inputDate < today) {
            calendarService
                .setIsGeweest(nextEvent.id as string)
                .then(() => getKalenderFeed())
                .finally(() => nextEvent = sortedAgendaItems.find((item) => !geweest(item.datum)))
            return nextEvent.datum;
        } else {
            return nextEvent.datum;
        }
    }

    function getAllEvents() {
        EventService.getAll()
            .then(filterVisibleEvents)
            .then((visibleEvents: IEvent[]) => setEvents(visibleEvents))
            .catch((e: any) => console.error(e));

        function filterVisibleEvents(res: IEvent[]) {
            return res.filter(({visible}) => visible);
        }
    }

    function getKalenderFeed() {
        calendarService
            .getFutureEvents()
            .then((res) => setAgendaItems(res))
            .catch(e => console.error(e))
    }

    useEffect(() => {
        getAllEvents();
        getKalenderFeed();
    }, []);

    return (
        <DataContext.Provider value={siteData}>
            {children}
        </DataContext.Provider>
    );
}
