import React, {useContext} from 'react';
import "./FooterComponent.css";
import instagram from "../../assets/instagram-icon.svg";
import facebook from "../../assets/facebook-icon.svg";
import {DataContext} from "../../context/DataContext";
import {useNavigate} from "react-router-dom";
import {datumDigitsToString} from "../../utils/helpers/utils";

function FooterComponent() {
    const {nextMeeting} = useContext(DataContext);
    const navigate = useNavigate();

    function calendar() {
        navigate('/agenda')
    }

    return (
        <footer>
            <section className="adres">
                Wijkcentrum Lopes Dias<br/>
                Lopes Diaslaan 213<br/>
                1222 VE Hilversum
            </section>
            <section className="social">
                <h3>Volg ons op Social Media</h3>
                <div className="logos">
                    <a href="https://www.instagram.com/fotoclubtgooi/" target='_blank' rel="noopener noreferrer">
                        <img className="instagram" src={instagram} alt="instagram icon"/>
                    </a>
                    <a href="https://www.facebook.com/www.fctgooi.nl/?locale=nl_NL" target='_blank'
                       rel="noopener noreferrer">
                        <img className="facebook" src={facebook} alt="facebook icon"/>
                    </a>
                </div>
            </section>
            {nextMeeting &&
                <section className="meet">
                    Volgende bijeenkomst:
                    <br/>
                    <span onClick={calendar}>{datumDigitsToString(nextMeeting)}</span>
                </section>
            }
        </footer>
    );
}

export default FooterComponent;