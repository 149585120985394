import React, {useEffect, useState} from 'react';
import "./NavBar.css";
import {Link} from 'react-router-dom';

function NavBar() {
    const currentUrl = window.location.href.split("/");
    const [isOpen, toggleIsOpen] = useState<boolean>(false);
    const [dropDown, toggleDropDown] = useState<boolean>(false);
    const [isScrolled, setIsScrolled] = useState(false);

    function openBurger() {
        if (isOpen) {
            if (dropDown) {
                toggleDropDown(false);
            }
            toggleIsOpen(false);
        } else {
            toggleIsOpen(true);
        }
    }

    useEffect(() => {
        if (currentUrl[3]?.length === 0) {
            const handleScroll = () => {
                const scrollPosition = window.scrollY || document.documentElement.scrollTop;
                setIsScrolled(scrollPosition > window.innerHeight / 8);
            };
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        } else {
            setIsScrolled(true);
        }
    }, [currentUrl]);

    return (
        <div id={"nav-container"}>
            <nav className={isOpen ? "open" : "closed"}>
                <Link to={"/"}>Home</Link>
                <div className={"title"} onClick={() => toggleDropDown(!dropDown)}>Club</div>
                <div id={`${!isScrolled ? "up" : "down"}`} className={`dropdown-${dropDown ? "open" : "closed"}`}>
                    <Link to={"/info"}>Info</Link>
                    <Link to={"/nieuws"}>Nieuws</Link>
                    <Link to={"/expositie"}>Exposities</Link>
                    <Link to={"/bondsfotowedstrijd"}>Bondsfotowedstrijd</Link>
                    <Link to={"/debesteclub"}>De Beste Club</Link>
                    <Link to={"/clubtochten"}>Clubtochten</Link>
                    <Link to={"/clubsuccessen"}>Clubsuccessen</Link>
                    <Link to={"/persoonlijkesuccessen"}>Persoonlijke successen</Link>

                </div>
                <Link to={"/agenda"}>Agenda</Link>
                <Link to={"/contact"}>Contact</Link>
            </nav>
            <div className={isOpen ? "burger-btn-open" : "burger-btn-closed"} onClick={() => openBurger()}>
                <div className={"bar"}/>
                <div className={"bar"}/>
                <div className={"bar"}/>
            </div>
        </div>

    );
}

export default NavBar;