export enum EventSoort {
    Clubtocht = "Clubtocht",
    Expositie = "Expositie",
    Bondsfotowedstrijd = "Bondsfotowedstrijd",
    DeBesteClub = "Debesteclub",
    ClubSuccessen = "ClubSuccessen",
    Actueel = "Actueel",
    PersoonlijkeSuccessen = "Persoonlijkesuccessen",
}

export enum Collections {
    calendar = "calendar",
    events = "events",
    fotovdmaand = "fotovdmaand",
    leden = "leden",
}

export enum AdminMenuItems {
    ManageCalendar = "Kalender beheren",
    ManageEvents = "Evenementen beheren",
    CreateEvent = "Evenement aanmaken",
    ManageMonthPhoto = "Foto van de maand beheren",
    MailingAdmin = "Mailing lijst  beheren"
}

export enum Alerts {
    Copyright = "Dit materiaal is beschermd door auteursrecht.\n\nHet kopiëren, downloaden, verspreiden, of op welke manier dan ook reproduceren van deze afbeelding zonder voorafgaande schriftelijke toestemming van de eigenaar is strikt verboden.\n\nAls je interesse hebt in het gebruik van deze afbeelding, neem dan contact op met de eigenaar voor toestemming.",
    NoImage = "Er is geen afbeelding beschikbaar voor deze activiteit",
    NoImages = "Er zijn geen afbeeldingen beschikbaar voor deze activiteit",
    NoDescription = "Er is geen beschrijving beschikbaar voor deze activiteit",
    NoTitle = "Er is geen titel beschikbaar voor deze activiteit",
    NoDate = "Er is geen datum beschikbaar voor deze activiteit",
    NoLocation = "Er is geen locatie beschikbaar voor deze activiteit",
    NoSoort = "Er is geen soort beschikbaar voor deze activiteit",
    NoEvent = "Er is geen activiteit beschikbaar voor deze pagina",
    NoEvents = "Er zijn geen activiteiten beschikbaar voor deze categorie",
    NoEventsFound = "Er zijn geen activiteiten gevonden",
    NoAgendaItems = "Er zijn geen agenda items beschikbaar",
    DateInPast = "Deze datum ligt in het verleden",
    InvalidUrl = "Deze url is niet geldig",
    InvalidEmail = "Dit email adres is niet geldig",
    DoublePhoto = "Deze afbeelding is al toegevoegd",
    NotLoggedIn = "U bent niet ingelogd",
    WrongCredentials = "Onjuiste inloggegevens",
    WrongDateFormat = "De datum moet in het formaat dd-mm-jjjj zijn",
    FVDMAlreadyPickedPhoto = "Deze foto is al eens foto van de maand geweest",
    InvalidMonth = "Maand moet een getal tussen 1 en 12 zijn",
    PresentOrFuture = "Jaar moet gelijk zijn aan of groter zijn dan het huidige jaar",
    FVDMAlreadyPickedMonthEr = "Er bestaat al een foto van de maand voor deze maand en dit jaar",
    FVDMCurrentMonth = "Je kan de foto van de maand van deze maand niet verwijderen"
}

export enum Prompts {
    NewImgUrl = "Geef een nieuwe url voor deze afbeelding",
    NewEmail = "Geef een nieuw email adres",
    NewAlt = "Geef een nieuwe alt tekst voor deze afbeelding",
    NewDate = "Geef een nieuwe datum voor deze activiteit in formaat dd-mm-jjjj",
    NewTitle = "Geef een nieuwe titel voor deze activiteit",
    NewLocation = "Geef een nieuwe locatie voor deze activiteit",
    NewBeschrijving = "Geef een nieuwe beschrijving voor deze activiteit",
    NewSoort = "Geef een nieuwe soort voor deze activiteit",
}

export enum Confirms {
    DeletePicture = "Weet u zeker dat u deze afbeelding wilt verwijderen?",
    DeleteEvent = "Weet u zeker dat u deze activiteit wilt verwijderen?",
    DeleteAgendaItem = "Weet u zeker dat u dit agenda item wilt verwijderen?",
    ChangeVisibility = "Weet u zeker dat u de zichtbaarheid van deze activiteit wilt veranderen?"
}

export const PageInfo = {
    home: {
        title: "Fotoclub 't Gooi",
        description: [
            "Fotoclub ’t Gooi is opgericht in 1924 en heeft ± 20 enthousiaste leden.",
            "Amateur-fotografen die in clubverband elkaar stimuleren tot het maken van betere foto’s.",
            "De leden komen eens in de 14 dagen, op woensdagavond bijeen in ",
            "Buurthuis Lopes Dias",
            "Lopes Diaslaan 213",
            "1222 VE Hilversum"
        ],
        keywords: "Fotoclub, 't Gooi, Fotoclub 't Gooi, Fotografie, Fotograferen, Fotograaf, Fotografen, Fotoclub Hilversum, Hilversum"
    },
    clubtochten: {
        titel: "Clubtochten",
        beschrijving: [
            "Tenminste één keer per jaar trekken we er met zijn allen op uit",
            "Samen gaan we naar een eerder gekozen bestemming om daar samen foto's te maken",
            "Naast de fotografische inhoud van de tocht heeft het onderlinge, ongedwongen contact ook een sociaal aspect",
            "En zeg nou zelf, even lekker eropuit is toch heerlijk",
            "Friesland, Groningen, België, we komen overal!"
        ]
    },
    exposities: {
        titel: "Exposities",
        beschrijving: [
            "Meerdere keren per jaar hebben we een expositie",
            "Deze worden gehouden op verschillende plekken en over verschillende thema's",
            "",
            "",
            "Hieronder enkele exposities van onze leden van de afgelopen jaren"
        ]
    },
    debesteclub: {
        titel: "De Beste Club (fotowedstrijd)",
        beschrijving: [
            "Elk jaar doen wij als fotoclub mee aan de De Beste Club fotowedstrijd",
            "Deze worden gehouden op verschillende plekken en over verschillende thema's",
            "",
            "Naast de fotografische inhoud van de tocht heeft het onderlinge, ongedwongen contact ook een sociaal aspect",
            "En zeg nou zelf, even lekker eropuit is toch heerlijk",
            "Friesland, Groningen, België, we komen overal!"
        ],
    },
    bondsfotowedstrijd: {
        titel: "Bondsfotowedstrijd",
        beschrijving: [
            "De Bondsfotowedstrijd is een activiteit van de Fotobond, Nederlands Centrum Vrijetijdsfotografie. ",
            "Eens per jaar kunnen alle aangesloten fotoclubs een serie van 10 foto’s insturen. ",
            "De inzendingen worden vervolgens beoordeeld door een externe jury, die daaruit de topgroepen kiest",
            "en aan een aantal individuele foto’s een bronzen-, zilveren- of een gouden sticker toekent."
        ]
    },
    Persoonlijkesuccessen: {
        titel: "Persoonlijke successen",
        beschrijving: [
            "In dit album worden de persoonlijk successen geplaatst. Dit kan zijn een wedstrijd resultaat of een publicatie"
        ]
    }
}

export const leden = [
    {value: 'Bob van Altena', label: 'Bob van Altena'},
    {value: 'Frans Beukenkamp', label: 'Frans Beukenkamp'},
    {value: 'Freek Buné', label: 'Freek Buné'},
    {value: 'Bert Caris', label: 'Bert Caris'},
    {value: 'Monique van Doesburg', label: 'Monique van Doesburg'},
    {value: 'Jan van Dongen', label: 'Jan van Dongen'},
    {value: 'Nico Grim', label: 'Nico Grim'},
    {value: 'John Jansen', label: 'John Jansen'},
];

export const links = [
    {url: 'http://www.fotobond.nl', label: 'Fotobond.nl'},
    {url: 'http://www.johnjansenfotografie.nl/', label: 'John Jansen'},
    {url: 'https://kuler.nl/fotografie/vrijwerk/', label: 'Wendy van Kuler'},
    {url: 'http://www.beeld.jacobschipper.nl/', label: 'Jacob Schipper'},
    {url: 'http://www.fhbfotogalerie.nl', label: 'Freek Buné'},
]