import React, {useContext} from 'react';
import AgendaItem from "../../components/agendaItem/AgendaItem";
import Header from "../../components/headerComponent/HeaderComponent";
import Footer from "../../components/footerComponent/FooterComponent";
import "./Kalender.css";
import {rightMouseBlocker} from "../../utils/helpers/utils";
import {DataContext} from "../../context/DataContext";

function Kalender() {
    const {sortedAgendaItems} = useContext(DataContext);

    return (
        <div id={"kalender-pagina-container"} onContextMenu={rightMouseBlocker}>
            <Header/>
            <div id="kalender-container">
                <h2>Agenda</h2>
                <div id={"kalender-items"}>
                    <AgendaItem soort={"Soort"} tijd={"Tijd"} bijzonderheden={"Bijzonderheden"}
                                programma={"Wat (gaan we doen)?"}
                                datum={"Datum"}/>

                    {sortedAgendaItems
                        .map(({soort, datum, tijd, van, tot, bijzonderheden, programma, link}) => {
                            return (
                                <AgendaItem
                                    soort={soort}
                                    datum={datum.split("-").reverse().join("-")}
                                    tijd={tijd}
                                    bijzonderheden={bijzonderheden}
                                    programma={programma}
                                    key={datum + programma}
                                    link={link}
                                />

                            );
                        })}
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Kalender;